import { Box, Button, Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import { Container } from 'components/Container';
import { NextSeo } from 'next-seo';
import Link from 'next/link';

export default function Custom404() {
  const { coloMode } = useColorMode();
  return (
    <Box p="40px">
      <NextSeo title={`404: This page could not be found`} />
      <Container>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minH={coloMode === 'dark' ? '65vh' : '65vh'}
        >
          <Text textTransform="uppercase" fontSize="14px" fontWeight="semibold" mb="10px">
            Error 404
          </Text>
          <Heading fontSize={['30px', '50px']} mb="20px">
            Page not found
          </Heading>
          <Box w={['100%', '380px']} textAlign="center">
            <Text lineHeight="1.9em">
              Sorry, the page you are looking for doesn&apos;t exist or has been moved. We suggest you back to home.
            </Text>
          </Box>
          <Link href="/">
            <a>
              <Button my="30px" variant="black" px="40px">
                Back to Home
              </Button>
            </a>
          </Link>
        </Flex>
      </Container>
    </Box>
  );
}
